import {Loader, Text} from "@audi/audi-ui-react";
import CheckForm from "./components/CheckForm";
import {useState} from "react";
import AppendHead from "react-append-head";

function MsiTermsApp(props) {
    const [tosLink, setTosLink] = useState(null);
    const [privacyLink, setPrivacyLink] = useState(null);

    const authState = props.authState;
    const token = props.userToken;
    const tosVersion = props.tos;
    const privacyVersion = props.privacy;
    const showTos = (tosVersion !== undefined);
    const showPrivacy = (privacyVersion !== undefined);

    const msiCss = "/default.css";

    let apiEndpoint = 'https://' + process.env.REACT_APP_API_ENDPOINT;
    let apiKey = process.env.REACT_APP_API_KEY;

    let loadToS = (tosVersion !== undefined && tosLink === null);
    let loadPrivacy = (privacyVersion !== undefined && privacyLink === null);

    if (loadToS) {
        asyncRequestToSSignedUrl()
    }
    if (loadPrivacy) {
        asyncSetPrivacyPolicyLink()
    }

    let stillLoading = (loadToS || loadPrivacy)

    return (
        <div className="main">
            <AppendHead>
                <link type="text/css" rel ="stylesheet" href={msiCss}/>
            </AppendHead>
            <Text variant="order2">MSI Platform Services</Text>
            {stillLoading && <Loader />}
            {!stillLoading &&
                <CheckForm tos={showTos} tosLink={tosLink} privacy={showPrivacy} privacyLink={privacyLink}
                           className="checkbox-form" onSubmit={getSubmitFormFunc(token, authState)}/>
            }
        </div>
    );

    function asyncSetPrivacyPolicyLink() {
        let privacyLink = (privacyVersion !== undefined ? process.env.REACT_APP_PRIVACY_LINK + privacyVersion.replace(".", "-") : "");
        setPrivacyLink(privacyLink);
    }

    function asyncRequestToSSignedUrl() {
        fetch(apiEndpoint + "/get-terms-link", {
            method: "POST",
            body: JSON.stringify({"token": token}),
            headers: new Headers({'content-type': 'application/json', 'x-api-key': apiKey}),
        })
            .then(res => res.json())
            .then(res => {
                console.log("response " + res);
                console.log(res);
                const url = res["signed_url"];
                setTosLink(url)
            });
    }
}

export default MsiTermsApp;

function getSubmitFormFunc(token, authState) {
    return function() {
        let apiEndpoint = 'https://' + process.env.REACT_APP_API_ENDPOINT;
        let idpDomain = 'https://' + process.env.REACT_APP_IDP_DOMAIN;
        let apiKey = process.env.REACT_APP_API_KEY;

        fetch(apiEndpoint + "/accept-tos", {
            method: "POST",
            body: JSON.stringify({"token": token}),
            headers: new Headers({'content-type': 'application/json', 'x-api-key': apiKey}),
        }).then(res => {
            if (res.status === 200) {
                console.log("response " + res);
                window.location.href = idpDomain + '/continue?state=' + authState;
            } else {
                console.error("Error response:", res);
            }
        });
    }
}