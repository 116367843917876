import React, {useState} from 'react';
import InputField from "./InputField";
import {Button, FormGroup, Loader, Text} from "@audi/audi-ui-react";

function CustomCheckForm(props) {

    const terms = props.terms;
    const settings = props.pageSettings;
    const [acceptedTerms, setAcceptedTerms] = useState([]);
    const [btnVisible, setBtnVisible] = useState(true);
    let termsInputs = [];

    terms.forEach((terms_obj, idx) => {
       let terms_id = terms_obj["terms_id"];
       let link_name = terms_obj["link_name"];
       let text_pattern = terms_obj["text_pattern"];
       let url = terms_obj["url"];
       let items = text_pattern.split("{{ link }}");
       let termsInput = <InputField key={"input-" + idx} num={idx} name={terms_id} title={link_name} text1={items[0]} text2={items.length > 1 ? items[1] : ""} link={url} clickFunc={termsChanged(terms_id)}/>;
        
       if (terms_obj.hide_checkbox) {
        termsInput = <Text key={"input-" + idx} as="p" className="spaced">
        { items[0]} <a href={url} target="_blank" rel="noopener noreferrer">{link_name}</a> {items.length > 1 ? items[1] : "" } 
            </Text>
       }
       
       termsInputs.push(termsInput);
    });

    let submitBtn = <Button id="submit-btn" variant="primary" className={btnVisible ? "" : "not-visible"} disabled={!areAllAccepted()} onClick={submit}>{getBtnLabel()}</Button>;
    let spinner = <Loader id="loading-spinner" className={btnVisible ? "not-visible": ""}/>;

    return <FormGroup id="form-group" className={props.className}>
        {termsInputs.map((input) => {
        return input
    })}
        {submitBtn}
        {spinner}
    </FormGroup>
    


    function getBtnLabel() {
        if(settings !== null && settings !== undefined &&  "button_label" in settings) {
            return settings["button_label"];
        }
        return "Submit";
    }

    function submit() {
        setBtnVisible(false);
        props.onSubmit();
    }

    function areAllAccepted() {
        let number = terms.filter(t => t.hide_checkbox === false).length
        return acceptedTerms.length === number;
    }

    function termsChanged(termsId) {
        return function() {
            setAcceptedTerms((oldAcceptedTerms) => {
                if(oldAcceptedTerms.includes(termsId)) {
                    return oldAcceptedTerms.filter(tId => tId !== termsId)
                }
                return [...oldAcceptedTerms, termsId]
            });
        }
    }
}

export default CustomCheckForm;