import {Loader, Text} from "@audi/audi-ui-react";
import {useEffect, useRef, useState} from "react";
import CustomCheckForm from "./components/CustomCheckForm";
import logo from './res/logo.png';
import AppendHead from 'react-append-head';

function CustomTermsApp(props) {
    const authState = props.authState;
    const token = props.userToken;
    const customTerms = props.customTerms;

    const [terms, setTerms] = useState(null);
    const [pageSettings, setPageSettings] = useState(null);
    const [customCss, setCustomCss] = useState(null);
    let apiEndpoint = 'https://' + process.env.REACT_APP_API_ENDPOINT;
    let apiKey = process.env.REACT_APP_API_KEY;

    const [customCssLoaded, setCustomCssLoaded] = useState(false)
    const [defaultCss, setDefaultCss] = useState("")
    const msiCss = "/default.css";

    useEffect(() => {
        fetch(apiEndpoint + "/custom/terms?includeAll=true&terms=" + customTerms, {
            method: "GET",
            headers: new Headers({'content-type': 'application/json', 'x-api-key': apiKey}),
        })
            .then(res => res.json())
            .then(res => {
                setTerms(res["termsToAccept"]);
                setPageSettings(res["pageSettings"]);
            });
    }, [apiEndpoint, apiKey, customTerms])

    useEffect(() => {
        if (pageSettings === null || pageSettings === undefined) {
            return;
        }
        const hasCustomCss = "custom_css" in pageSettings;
        let customCssUrl = hasCustomCss ? pageSettings["custom_css"] : "";
        setCustomCss(customCssUrl);
        if (!hasCustomCss) {
            setCustomCssLoaded(true);
        }
    }, [pageSettings])

    useEffect(() => {
        if (customCssLoaded) {
            setDefaultCss(msiCss)
        }
    }, [customCssLoaded])

    if (customCss === null) {
        return <div/>;
    }

    if (!customCssLoaded) {
        return (
            <AppendHead>
                <link onLoad={() => {
                    setCustomCssLoaded(true)
                }} type="text/css" rel="stylesheet" href={customCss}/>
            </AppendHead>
        )
    }
    return (
        <div className="main" id="main-container">
            <AppendHead>
                <link type="text/css" rel="stylesheet" href={msiCss}/>
                <link onLoad={() => {
                    setCustomCssLoaded(true)
                }} type="text/css" rel="stylesheet" href={customCss}/>
            </AppendHead>

            <img src={getLogo(pageSettings)} id={"logo"} alt={"logo"}/>
            <Text variant="order2" id="title-text">{getHeaderText(props.clientName, pageSettings)}</Text>
            <Text variant="copy1" id="updated-version-sub-text" className={"sub-text"}>{getSubText(pageSettings)}</Text>
            {terms === null && <Loader/>}
            {terms !== null &&
                <CustomCheckForm id="checkbox-form-box" terms={terms} pageSettings={pageSettings}
                                 className="checkbox-form" onSubmit={getSubmitFormFunc(token, authState)}/>
            }
        </div>
    );
}

export default CustomTermsApp;

function getLogo(pageSettings) {
    if (pageSettings !== null && pageSettings !== undefined && "custom_logo" in pageSettings) {
        return pageSettings["custom_logo"];
    }
    return logo;
}

function getSubText(pageSettings) {
    if (pageSettings !== null && pageSettings !== undefined && "sub_text" in pageSettings) {
        return pageSettings["sub_text"];
    }
    return "";
}

function getHeaderText(clientName, pageSettings) {
    if (pageSettings !== null && pageSettings !== undefined && "header_text" in pageSettings) {
        let headerTextPattern = pageSettings["header_text"];
        return headerTextPattern.replace("{{ client_name }}", clientName);
    }
    return clientName;
}

function getSubmitFormFunc(token, authState) {
    return function () {
        let apiEndpoint = 'https://' + process.env.REACT_APP_API_ENDPOINT;
        let idpDomain = 'https://' + process.env.REACT_APP_IDP_DOMAIN;
        let apiKey = process.env.REACT_APP_API_KEY;

        fetch(apiEndpoint + "/custom/accept-terms", {
            method: "POST",
            body: JSON.stringify({"token": token}),
            headers: new Headers({'content-type': 'application/json', 'x-api-key': apiKey}),
        }).then(res => {
            if (res.status === 200) {
                window.location.href = idpDomain + '/continue?state=' + authState;
            } else {
                console.error("Error response:", res);
            }
        });
    }
}