import React, {useState} from 'react';
import InputField from "./InputField";
import {Button, FormGroup, Loader} from "@audi/audi-ui-react";

function CheckForm(props) {

    const [tosChecked, setTosChecked] = useState(!props.tos);
    const [privacyChecked, setPrivacyChecked] = useState(!props.privacy);
    const [btnVisible, setBtnVisible] = useState(true);

    let tosInput = <InputField num={1} name="tos" title="Terms and Conditions" text1="I have read the" text2="and I agree to them." link={props.tosLink} clickFunc={tosChanged}/>;
    let privacyInput = <InputField num={2} name="privacy" title="Privacy Policy" text1="I have read the" text2="and took note of it." link={props.privacyLink} clickFunc={privacyChanged}/>;
    let submitBtn = <Button variant="primary" className={btnVisible ? "" : "not-visible"} disabled={!(tosChecked === true && privacyChecked === true)} onClick={submit}>Submit</Button>;
    let spinner = <Loader className={btnVisible ? "not-visible": ""}/>;

    if(props.tos && props.privacy) {
        return (  <FormGroup className={props.className}> {tosInput} {privacyInput} {submitBtn} {spinner} </FormGroup>)
    } else if(props.tos) {
        return (<FormGroup className={props.className}> {tosInput} {submitBtn} {spinner} </FormGroup>)
    } else if(props.privacy) {
        return (<FormGroup className={props.className}> {privacyInput} {submitBtn} {spinner} </FormGroup>)
    }
    return (<form/>);

    function submit() {
        setBtnVisible(false);
        props.onSubmit();
    }

    function tosChanged(e) {
        setTosChecked(e.target.checked);
    }

    function privacyChanged(e) {
        setPrivacyChecked(e.target.checked);
    }

}

export default CheckForm;