import './clean.css';
import React from 'react';
import MsiTermsApp from "./MsiTermsApp";
import CustomTermsApp from "./CustomTermsApp";

function App() {

    let hash = window.location.hash.substr(1);
    let hashParams = hash.split('&').reduce(function (res, item) {
        let parts = item.split('=');
        res[parts[0]] = parts[1];
        return res;
    }, {});

    const authState = getParameterByName("state");
    const token = hashParams["userToken"];
    const tosVersion = hashParams["tos"];
    const privacyVersion = hashParams["privacy"];
    const customTerms = hashParams["custom_terms"];
    const clientName = hashParams["client_name"];

    if(tosVersion !== undefined || privacyVersion !== undefined) {
        return <MsiTermsApp authState={authState} userToken={token} tos={tosVersion} privacy={privacyVersion} />
    } else {
        return <CustomTermsApp clientName={decodeURIComponent(clientName)} authState={authState} userToken={token} customTerms={customTerms} />
    }
}

export default App;

function getParameterByName(name) {
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    let results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}