import React from 'react';
import {Checkbox} from "@audi/audi-ui-react";

function InputField(props) {
    return (
        <Checkbox
            id={"input-container-" + props.num}
            inputId={"checkbox-input-" + props.num}
            name={props.name}
            spaceStackEnd="l"
            required={true}
            onChange={props.clickFunc}>
            {props.text1} <a href={props.link} target="_blank" rel="noopener noreferrer">{props.title}</a> {props.text2}
        </Checkbox>
    )
}

export default InputField;